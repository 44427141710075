import { template as template_b0b4f8641d874944a1c1bed30be6c402 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b0b4f8641d874944a1c1bed30be6c402(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
